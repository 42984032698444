@import "variables";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap.scss";
@import "../../../node_modules/jasny-bootstrap/scss/jasny-bootstrap.scss";

html {
    position: relative;
    min-height: 100%;
}

body {
    margin-bottom: 60px;
    overflow-y: scroll;

    & > .container {
        padding-top: 70px;
    }
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px; // Fixed
}

body, label, .checkbox label {
    font-weight: 300;
}

.btn,
.dropdown-menu > li > a,
.nav-tabs > li > a,
.pagination > li > a,
.table > tbody > tr,
.table > tbody > tr > td {
    transition: all 300ms;
}

@function set-navbar-divider-color($color) {
    @if (lightness($color) > 50) {
        @return lighten($color, 25%);
    } @else {
        @return darken($color, 25%);
    }
}

.navbar {
    margin-right: -1px;

    .nav {
        border-left: 1px solid set-navbar-divider-color($navbar-default-bg);

        & > li {
            border-right: 1px solid set-navbar-divider-color($navbar-default-bg);
        }
    }
}

.navbar-fixed-top {
    border-width: 0 0 2px;
}

.breadcrumb {
    border-color: $panel-default-border;
    border-style: solid;
    border-width: 1px;
}

.panel-heading {
    border-bottom-width: 2px;
}

.btn {
    border-bottom-width: 2px;

    &.btn-default {
        border-bottom-color: darken($btn-default-bg, 30%);
    }
    &.btn-primary {
        border-bottom-color: darken($btn-primary-bg, 30%);
        text-shadow: 0 -1px darken($btn-primary-bg, 15%);
    }
    &.btn-success {
        border-bottom-color: darken($btn-success-bg, 30%);
        text-shadow: 0 -1px darken($btn-success-bg, 15%);
    }
    &.btn-info {
        border-bottom-color: darken($btn-info-bg, 30%);
        text-shadow: 0 -1px darken($btn-info-bg, 15%);
    }
    &.btn-warning {
        border-bottom-color: darken($btn-warning-bg, 30%);
        text-shadow: 0 -1px darken($btn-warning-bg, 15%);
    }
    &.btn-danger {
        border-bottom-color: darken($btn-danger-bg, 30%);
        text-shadow: 0 -1px darken($btn-danger-bg, 15%);
    }
}

.table-hover > tbody > tr:hover {
    cursor: pointer;
}

.table-responsive {
    border-top: 1px solid $table-border-color;
}

.row-valign {
    & > * {
        display: inline-block;
        float: none;
        vertical-align: middle;
    }
}

.huge {
    font-size: floor(($font-size-base * 3));
}

.flush-bottom {
    margin-bottom: 0 !important;
}

.btn-file > input {
    width: 200%;
}

.panel-footer.panel-pagination {

    .page-count {
        padding-top: 8px;
    }

    .pagination {
        margin: 2px 0;
        white-space: nowrap;
    }
}

.breadcrumb-plain {
    border-style: none;
}

.nav-tabs-panel {
    border-bottom-width: 0;
}

.tab-pane {

    .panel-tab-pane {
        border-color: $nav-tabs-border-color;
    }

    &:first-child .panel-tab-pane {
        border-top-left-radius: 0;
    }
}

.panel-roles {
    padding: 0;
    background-color: transparent;
    border: none;
    box-shadow: none;

    .panel-heading {
        padding: 0 0 $padding-large-vertical 0;
        border: none;
    }

    .panel-heading + .panel-collapse > .panel-body {
        border-top: 1px solid $gray-darker;
    }
}

.modal-dialog {
    z-index: 1050;
}